import { authHeader, handleResponse } from '../../Helpers';

const { REACT_APP_API_URL } = process.env;

export const fileService = {
    create,
    getById,
    getByOwnerId,
    delete: _delete,
};

function create(file) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: file
    };
    return fetch(`${REACT_APP_API_URL}/files`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${REACT_APP_API_URL}/files/${id}`, requestOptions).then(handleResponse);
}

function getByOwnerId(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${REACT_APP_API_URL}/files/owner/${id}`, requestOptions).then(handleResponse);
}

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(`${REACT_APP_API_URL}/files/${id}`, requestOptions).then(handleResponse);
}