import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, OutlinedInput, InputAdornment, IconButton, FormControl, InputLabel } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';

import { userActions } from '../../Redux/actions/userActions';
import picture from '../../Assets/login.png';
import { DefaultSnackbar } from '../../Components';
import './Register.css'

export default function Register() {
    const [firstname, setFirstname] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [hide, setHide] = useState(true);
    const error = useSelector((state) => state.user);

    const dispatch = useDispatch();

    function handleSubmit(e) {
        e.preventDefault();

        if (firstname && name && email && password) {
            const user = {
                "demo": false,
                "active": true,
                "email": email,
                "firstname": firstname,
                "name": name,
                "password": password
            }
            dispatch(userActions.register(user));
            if (error.error)
                enqueueSnackbar("Erreur d'inscription : " + error.error)
        } else enqueueSnackbar("Veuillez remplir tous les champs");
    }

    return (
        <div className="registerContainer">
            <DefaultSnackbar />
            <div className="registerContainer-left">
                <form className="registerContent" onSubmit={handleSubmit}>
                    <div className="title">Inscription</div>
                    <TextField
                        label="Prénom"
                        variant="outlined"
                        onChange={e => setFirstname(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        label="Nom"
                        variant="outlined"
                        onChange={e => setName(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        label="Email"
                        variant="outlined"
                        type='email'
                        onChange={e => setEmail(e.target.value)}
                        fullWidth
                    />
                    <div className="password">
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="password-label">Mot de passe</InputLabel>
                            <OutlinedInput
                                label="Mot de passe"
                                type={hide ? 'password' : 'text'}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setHide(!hide)}
                                            edge="end"
                                        >
                                            {hide ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    <button className="button" type="submit">S'inscrire</button>
                </form>
                <footer className="copyright">© 2024 Sésamoïde</footer>
            </div>
            <div className="registerContainer-right">
                <img className="picture" src={picture} alt="Page de connexion" />
            </div>
        </div>
    );
}