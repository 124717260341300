import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { TextField, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { userService } from '../../Redux/services';
import picture from '../../Assets/login.png';
import './Reset.css'

export default function Reset() {
    const { id, token } = useParams();
    const [tokenExpired, setTokenExpired] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        const decode = JSON.parse(atob(token.split('.')[1]));
        if (decode.exp * 1000 < new Date().getTime()) {
            setTokenExpired(true)
        }
    }, [token]);

    function handleSubmit(e) {
        e.preventDefault();

        if (password && confirmPassword) {
            if (password === confirmPassword)
                userService.resetPassword(id, password).then(() => { window.location.href = `/login` })
            else alert("Les mots de passe ne correspondent pas");
        } else alert("Veuillez remplir tous les champs");
    }

    return (
        <div className="resetContainer">
            <div className="resetContainer-left">
                <form className="resetContent" onSubmit={handleSubmit}>
                    <IconButton sx={{ position: "absolute", top: "32px", left: "32px" }} onClick={() => window.location.href = `/`}><ArrowBack sx={{ color: "#000", width: "50px", height: "auto" }} /></IconButton>
                    <div className="title">Réinitialisation mot de passe</div>
                    {tokenExpired ? <div className="resetBoxExpired">Lien expiré, veuillez recommencer</div> :
                        <>
                            <div className="resetBox">
                                <TextField
                                    label="Mot de passe"
                                    variant="outlined"
                                    onChange={e => setPassword(e.target.value)}
                                    fullWidth
                                />
                                <TextField
                                    label="Confirmation mot de passe"
                                    variant="outlined"
                                    onChange={e => setConfirmPassword(e.target.value)}
                                    fullWidth
                                />
                            </div>
                            <button className="resetButton" type="submit">Réinitialiser</button>
                        </>
                    }
                </form>
            </div>
            <div className="resetContainer-right">
                <img className="picture" src={picture} alt="Page de connexion" />
            </div>
        </div>
    );
}