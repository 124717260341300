import { patientConstants } from '../constants';

export function patient(state = {}, action) {
    switch (action.type) {
        case patientConstants.CREATE_REQUEST:
            return {
                loading: true
            };
        case patientConstants.CREATE_SUCCESS:
            return {
                state,
            };
        case patientConstants.CREATE_FAILURE:
            return {
                error: action.error
            };
        case patientConstants.GETALL_REQUEST:
            return {
                loading: true
            };
        case patientConstants.GETALL_SUCCESS:
            return {
                items: action.patients
            };
        case patientConstants.GETALL_FAILURE:
            return {
                error: action.error
            };
        case patientConstants.GETBYID_REQUEST:
            return {
                loading: true
            };
        case patientConstants.GETBYID_SUCCESS:
            return {
                item: action.patient
            };
        case patientConstants.GETBYID_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}