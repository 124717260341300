export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    GETPATIENTS_REQUEST: 'USERS_GETPATIENTS_REQUEST',
    GETPATIENTS_SUCCESS: 'USERS_GETPATIENTS_SUCCESS',
    GETPATIENTS_FAILURE: 'USERS_GETPATIENTS_FAILURE',

    ADDPATIENTS_REQUEST: 'USERS_ADDPATIENTS_REQUEST',
    ADDPATIENTS_SUCCESS: 'USERS_ADDPATIENTS_SUCCESS',
    ADDPATIENTS_FAILURE: 'USERS_ADDPATIENTS_FAILURE',

    UPDATEPATIENTS_REQUEST: 'USERS_UPDATEPATIENTS_REQUEST',
    UPDATEPATIENTS_SUCCESS: 'USERS_UPDATEPATIENTS_SUCCESS',
    UPDATEPATIENTS_FAILURE: 'USERS_UPDATEPATIENTS_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',
};
