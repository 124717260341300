import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, IconButton } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { ArrowBack, Close, Delete } from '@mui/icons-material'
import useClasses from '../../Components/UseClasses';
import PropTypes from 'prop-types';
import moment from 'moment';
import Rdate from 'react-datetime';

import { patientActions } from '../../Redux/actions';

import './CtrlAgenda.css';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const filter = createFilterOptions();

function getLast(array) {
  return array[array.length - 1];
}

function getFirst(array) {
  return array[0];
}

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

function round(date, duration, method) {
  return moment(Math[method]((+date) / (+duration)) * (+duration));
}

export default function CtrlAgenda(props) {
  const { patients, itemColors, selectedCells, userId, slotTime } = props;
  const dispatch = useDispatch();
  var now = new Date();
  const [meetingId, setMeetingId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [meeting, setMeeting] = useState({
    name: '',
    classes: 'color-1',
    startDateTime: round(moment(now), moment.duration(5, "minutes"), "ceil"),
    endDateTime: round(moment(now), moment.duration(5, "minutes"), "ceil").add(slotTime, 'Minutes'),
    patientId: null,
    userId: userId,
  })
  const [inputAuto, setInputAuto] = useState(meeting.name)
  const classes = useClasses(styles);
  const [modalStyle] = useState(getModalStyle);
  const [patientParams, setPatientParams] = useState({
    firstname: '',
    name: '',
    sexe: '',
    birthday: '',
    phone: '',
    mail: '',
    doctor: '',
    job: '',
    address: '',
    sport: '',
    soles: false,
    history: {
      trauma: [],
      surgery: [],
      treatment: ''
    },
    userId: userId
  });
  const [patient, setPatient] = useState({
    phone: '',
    mail: '',
  })
  var meetingModal = document.getElementById('agendaCtrls-meeting')
  var patientModal = document.getElementById('agendaCtrls-patient')
  var patientCreationModal = document.getElementById('agendaCtrls-createPatient')

  useEffect(() => {
    if (itemColors) {
      setMeeting({ ...meeting, classes: Object.keys(itemColors)[0] })
    }
    if (!selectedCells) {
      let start = now
      let endT = moment(now).add(slotTime, 'Minutes');
      setEditMode(false)
      setMeeting({ ...meeting, name: '', startDateTime: start, endDateTime: endT })
      return
    }

    if (selectedCells && selectedCells[0] && selectedCells[0]._id) {
      let start = moment(selectedCells[0].startDateTime);
      let endT = moment(selectedCells[0].endDateTime);
      setEditMode(true)
      setMeeting({ ...meeting, name: selectedCells[0].name, classes: selectedCells[0].classes, startDateTime: start, endDateTime: endT, patientId: selectedCells[0].patientId })
      setMeetingId(selectedCells[0]._id);
      setInputAuto(selectedCells[0].name)
      return
    }

    if (selectedCells && selectedCells.length === 1) {
      let start = moment(getFirst(selectedCells));
      let endT = moment(getLast(selectedCells)).add(slotTime, 'Minutes');
      setEditMode(false)
      setMeeting({ ...meeting, name: '', startDateTime: start, endDateTime: endT })
      return
    }

    if (selectedCells && selectedCells.length > 0) {
      let start = moment(getFirst(selectedCells));
      let endT = moment(getLast(selectedCells)) || now;
      setEditMode(false)
      setMeeting({ ...meeting, name: '', startDateTime: start, endDateTime: endT })
    }
  }, [])

  useEffect(() => {
    if (meeting.patientId) {
      document.getElementsByClassName(classes.paper)[0].style.width = '996px';
      patientModal.style.display = 'flex';
      var tmp = patients[patients.findIndex((element) => element._id === meeting.patientId)]
      setPatient({ phone: tmp.phone, mail: tmp.mail })
    }
    else {
      if (patientModal) {
        patientModal.style.display = 'none';
      }
      document.getElementsByClassName(classes.paper)[0].style.width = '564px';
    }
  }, [meeting, patientModal])

  const handleChange = event => {
    if (event.target.tagName === 'BUTTON') {
      event.preventDefault();
    }
    setMeeting({ ...meeting, [event.target.id.split('-')[0]]: event.target.value });
  }

  const handleDateChange = (value, type) => {
    const tmp = moment(value);
    if (type === "startDateTime") {
      setMeeting({ ...meeting, startDateTime: value.toDate(), endDateTime: tmp.add(slotTime, 'Minutes').toDate() })
    }
    else {
      setMeeting({ ...meeting, endDateTime: value.toDate() })
    }
  }

  const handleAutoChange = (value) => {
    setMeeting({ ...meeting, name: value ? value.firstname + ' ' + value.name : '', patientId: value ? value._id : null })
  }

  const handleInputChange = (event, value) => {
    event.preventDefault();
    if (!value)
      setMeeting({ ...meeting, name: '', patientId: null })
    setInputAuto(value)
  }

  // const dispatchEvent = obj => {
  //   var newAdded = []
  //   var { items } = props;
  //   if (obj['multiple']) {
  //     var array = obj['multiple']
  //     Object.keys(array).forEach(function (key) {
  //       var newAr = array[key].filter(function (val, ind) {
  //         return array[key].indexOf(val) == ind;
  //       })
  //       var start = newAr[0];
  //       var endT = newAr[newAr.length - 1] || now;
  //       var lasobj = {
  //         _id: guid(),
  //         name: obj.name,
  //         startDateTime: new Date(start),
  //         endDateTime: new Date(endT),
  //         classes: obj.classes,
  //         patientId: obj.patientId,
  //         userId: obj.userId,
  //       }
  //       items.push(lasobj)
  //       newAdded.push(lasobj)
  //     });
  //     return props.Addnew(items, newAdded)
  //   }
  //   obj._id = guid();
  //   items.push(obj)
  //   props.Addnew(items, obj)
  // }

  const addEvent = e => {
    if (meeting.name.length < 1) {
      return;
    }

    if (selectedCells && selectedCells.length > 0) {

      var obj = selectedCells.reduce((r, v, i, a, k = v.substring(0, 10)) => ((r[k] = r[k] || []).push(v), r), {});

      if (Object.values(obj).length > 1) {
        var newObj = {
          name: meeting.name,
          startDateTime: new Date(meeting.startDateTime),
          endDateTime: new Date(meeting.endDateTime),
          classes: meeting.classes,
          patientId: meeting.patientId,
          userId: meeting.userId,
          multiple: obj
        }
        return props.Addnew(newObj);
      }
    }

    var newObj = {
      name: meeting.name,
      startDateTime: new Date(meeting.startDateTime),
      endDateTime: new Date(meeting.endDateTime),
      classes: meeting.classes,
      patientId: meeting.patientId,
      userId: meeting.userId,
    }
    props.Addnew(newObj);
  }

  const updateEvent = e => {
    if (selectedCells[0]._id) {
      var newObj = {
        _id: selectedCells[0]._id,
        name: meeting.name,
        startDateTime: new Date(meeting.startDateTime),
        endDateTime: new Date(meeting.endDateTime),
        classes: meeting.classes,
        patientId: meeting.patientId,
        userId: meeting.userId,
      }
      if (props.edit) {
        props.edit(newObj);
      }
    }
  }

  const handleCreation = e => {
    e.preventDefault();
    patientCreationModal.style.display = 'flex';
    meetingModal.style.display = 'none';
  }

  const handleBack = e => {
    e.preventDefault();
    document.getElementById('back-button').style.display = 'none';
    setPatientParams({
      ...patientParams,
      firstname: '',
      name: '',
      phone: '',
      mail: '',
    })
    patientCreationModal.style.display = 'none';
    meetingModal.style.display = 'flex';
  }

  const handleChangePatient = event => {
    const target = event.target.id ? event.target.id : event.target.name
    setPatientParams({ ...patientParams, [target]: event.target.value });
  }

  const handleValidation = () => {
    let formIsValid = true;

    if (!patientParams.firstname)
      formIsValid = false;

    // if (typeof patientParams.firstname !== "undefined") {
    //   if (!patientParams.firstname.match(/^[a-zA-Z]+$/))
    //     formIsValid = false;
    // }

    if (!patientParams.name)
      formIsValid = false;

    // if (typeof patientParams.name !== "undefined") {
    //   if (!patientParams.name.match(/^[a-zA-Z]+$/))
    //     formIsValid = false;
    // }

    // if (!patientParams.mail) {
    //   formIsValid = false;
    // }

    // if (typeof patientParams.mail !== "undefined") {
    //   let lastAtPos = patientParams.mail.lastIndexOf('@');
    //   let lastDotPos = patientParams.mail.lastIndexOf('.');

    //   if (!(lastAtPos < lastDotPos && lastAtPos > 0 && patientParams.mail.indexOf('@@') === -1 && lastDotPos > 2 && (patientParams.mail.length - lastDotPos) > 2)) {
    //     formIsValid = false;
    //   }
    // }

    return formIsValid;
  }

  const handleSubmitPatient = () => {
    if (handleValidation()) {
      dispatch(patientActions.create(patientParams))
      setPatientParams({
        ...patientParams,
        firstname: '',
        name: '',
        phone: '',
        mail: '',
      })
      patientCreationModal.style.display = 'none';
      meetingModal.style.display = 'flex';
    }
    else {
      alert("Formulaire incorrect.")
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    if (meeting.patientId)
      addEvent(e);
    else
      alert("Vous n'avez pas renseigné de patient")
  }

  const handleEdit = e => {
    e.preventDefault();
    if (meeting.patientId)
      updateEvent(e);
    else
      alert("Vous n'avez pas renseigné de patient");
  }

  const handleDelete = e => {
    e.preventDefault();
    var object = { ...meeting, _id: meetingId }
    props.delete(object);
  }

  var itc = Object.keys(itemColors)
  var colors = itc.map(function (item, idx) {

    return <div style={{
      background: itemColors[item]
    }} className="agendCtrls-radio-buttons" key={item}>
      <button id="classes" value={item} className={meeting.classes === item ? 'agendCtrls-radio-button--checked' : 'agendCtrls-radio-button'} onClick={handleChange} />
    </div>
  })

  return (
    <div style={modalStyle} className={classes.paper}>
      <div className={classes.containerModal}>
        {patientCreationModal && patientCreationModal.style.display === 'flex' ?
          <IconButton
            id='back-button'
            onClick={handleBack}
            className="agendCtrls-backButton"
          >
            <ArrowBack />
          </IconButton> : null}
        <IconButton
          onClick={props.onClose}
          className="agendCtrls-closeButton"
        >
          <Close />
        </IconButton>
        <div className='agendCtrls-wrapper'>
          <div id='agendaCtrls-meeting' className={classes.contentModal}>
            <div className={classes.contentFields}>
              <div className="agendCtrls-inline">
                <label>Nom du patient</label>
                <Autocomplete
                  inputValue={inputAuto}
                  fullWidth
                  onChange={(e, newValue) => {
                    handleAutoChange(newValue)
                  }}
                  onInputChange={(event, value) => handleInputChange(event, value)}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some((option) => { let test = option.firstname + ' ' + option.name; return test.includes(inputValue) });
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        input: `Créer un nouveau patient`,
                      });
                    }
                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="name"
                  options={patients}
                  getOptionLabel={(option) => option.firstname + ' ' + option.name}
                  renderOption={(props, option) => <li key={option._id} {...props} onClick={option._id ? props.onClick : handleCreation} >{option._id ? option.firstname + ' ' + option.name : option.input}</li>}
                  sx={{ paddingLeft: 1, paddingRight: 1 }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Rechercher..."
                      variant='standard'
                      sx={{ paddingBottom: "16px" }}
                    />
                  )}
                />
              </div>
              <div className="agendCtrls-inline">
                <label>Couleur</label>
                <div className="agendCtrls-radio-wrapper">
                  {colors}
                </div>
              </div>
            </div>
            <div className="agendCtrls-timePicker-wrapper">
              <div className="agendCtrls-time-picker">
                <label>Début de séance</label>
                <Rdate id="startDateTime" value={meeting.startDateTime} onChange={(value) => handleDateChange(value, 'startDateTime')} input={false} initialViewMode="time" timeConstraints={{ minutes: { step: 5 } }}></Rdate>
              </div>
              <div className="agendCtrls-time-picker">
                <label>Fin de séance</label>
                <Rdate id="endDateTime" value={meeting.endDateTime} onChange={(value) => handleDateChange(value, 'endDateTime')} input={false} initialViewMode="time" timeConstraints={{ minutes: { step: 5 } }}></Rdate>
              </div>
            </div>

          </div>
          <div id='agendaCtrls-patient' className={`${classes.contentPatientModal} agendCtrls-fields`}>
            <div id='center'>Coordonnées</div>
            <div>Téléphone : <span>{patient.phone}</span></div>
            <div>Mail : <span>{patient.mail}</span></div>
            {meetingId ? <div><a href={`/patients/${meeting.patientId}`}>Voir fiche patient</a></div> : null}
          </div>
          <div id='agendaCtrls-createPatient' className={classes.contentPatientModal} style={{ display: 'none' }}>
            <TextField variant='standard' required id="firstname" label="Prénom" value={patientParams.firstname} onChange={handleChangePatient} className={classes.item} />
            <TextField variant='standard' required id="name" label="Nom" value={patientParams.name} onChange={handleChangePatient} className={classes.item} />
            <TextField variant='standard' id="phone" label="Téléphone" value={patientParams.phone} onChange={handleChangePatient} className={classes.item} />
            <TextField variant='standard' id="mail" label="Mail" value={patientParams.mail} onChange={handleChangePatient} className={classes.item} type="email" />
          </div>
        </div>
      </div>
      <div className='agendCtrls-controls'>
        <div style={{ width: '32px' }} />
        {patientCreationModal && patientCreationModal.style.display === 'flex' ? <button className="agendCtrls-input" onClick={handleSubmitPatient}>Créer Patient</button> : <button onClick={editMode ? handleEdit : handleSubmit} className="agendCtrls-input">Sauvegarder</button>}
        {meetingId ? <IconButton
          className='agendCtrls-remove'
          onClick={handleDelete}
        >
          <Delete />
        </IconButton> : <div className='agendCtrls-remove' />}
      </div>
    </div>
  );
}

const styles = theme => ({
  paper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gap: '32px',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    width: '564px',
    maxWidth: '90vw',
    maxHeight: '90vh',
    overflow: 'auto',
    overflowX: 'hidden',
    transition: "width .5s ease-in-out",
  },
  titleModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  containerModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
  },
  contentModal: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    flexWrap: 'wrap',
    height: '100%',
    width: '100%',
  },
  contentFields: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: '500px'
    },
  },
  contentPatientModal: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    padding: '0px 12px',
    width: '100%',
  },
  item: {
    width: '100%'
  },
});

CtrlAgenda.propTypes = {
  patients: PropTypes.array,
  itemColors: PropTypes.object,
  selectedCells: PropTypes.array,
  edit: PropTypes.func,
  delete: PropTypes.func,
  Addnew: PropTypes.func,
  onClose: PropTypes.func,
  userId: PropTypes.string
};

CtrlAgenda.defaultProps = {
  patients: [],
  itemColors: {},
  selectedCells: [],
  userId: '',
}