import { patientConstants, userConstants } from '../constants';
import { patientService } from '../services';

export const patientActions = {
    create,
    update,
    getAll,
    getById
};

function create(patient) {
    return dispatch => {
        dispatch(request());
        patientService.create(patient)
            .then(
                resp => {
                    patient._id = resp.id;
                    dispatch(success(patient))
                },
                error => dispatch(failure(error.toString())),
            );
    };

    function request() { return { type: userConstants.ADDPATIENTS_REQUEST } }
    function success(patient) { return { type: userConstants.ADDPATIENTS_SUCCESS, patient } }
    function failure(error) { return { type: userConstants.ADDPATIENTS_FAILURE, error } }
}

function update(patient) {
    return dispatch => {
        dispatch(request());
        patientService.update(patient)
            .then(
                resp => {
                    const updatedPatient = resp.patient
                    dispatch(success(updatedPatient))
                },
                error => dispatch(failure(error.toString())),
            );
    };

    function request() { return { type: userConstants.UPDATEPATIENTS_REQUEST } }
    function success(updatedPatient) { return { type: userConstants.UPDATEPATIENTS_SUCCESS, updatedPatient } }
    function failure(error) { return { type: userConstants.UPDATEPATIENTS_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request());
        patientService.getAll()
            .then(
                patients => dispatch(success(patients)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: patientConstants.GETALL_REQUEST } }
    function success(patients) { return { type: patientConstants.GETALL_SUCCESS, patients } }
    function failure(error) { return { type: patientConstants.GETALL_FAILURE, error } }
}

function getById() {
    return dispatch => {
        dispatch(request());
        patientService.getById()
            .then(
                patient => dispatch(success(patient)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: patientConstants.GETBYID_REQUEST } }
    function success(patient) { return { type: patientConstants.GETBYID_SUCCESS, patient } }
    function failure(error) { return { type: patientConstants.GETBYID_FAILURE, error } }
}