import { authHeader, handleResponse } from '../../Helpers';

const { REACT_APP_API_URL } = process.env;

export const patientService = {
    create,
    update,
    getAll,
    getById,
    remove
};

function create(patient) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(patient)
    };
    return fetch(`${REACT_APP_API_URL}/patients`, requestOptions).then(handleResponse);
}

function update(patient) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(patient)
    };
    return fetch(`${REACT_APP_API_URL}/patients/${patient._id}`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${REACT_APP_API_URL}/patients`, requestOptions).then(handleResponse);
}

function getById(patientId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${REACT_APP_API_URL}/patients/${patientId}`, requestOptions).then(handleResponse);
}

function remove(patientId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader()
    };
    return fetch(`${REACT_APP_API_URL}/patients/remove/${patientId}`, requestOptions).then(handleResponse);
}