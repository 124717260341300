import { authHeader, handleResponse } from '../../Helpers';

const { REACT_APP_API_URL } = process.env;

export const traumaService = {
    create,
    getAll,
    getById,
    getByUserId,
    delete: _delete,
};

function create(trauma) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(trauma)
    };
    return fetch(`${REACT_APP_API_URL}/traumas`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${REACT_APP_API_URL}/traumas`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${REACT_APP_API_URL}/traumas/${id}`, requestOptions).then(handleResponse);
}

function getByUserId(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${REACT_APP_API_URL}/traumas/user/${userId}`, requestOptions).then(handleResponse);
}

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(`${REACT_APP_API_URL}/traumas/${id}`, requestOptions).then(handleResponse);
}