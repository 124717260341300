import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, OutlinedInput, InputAdornment, IconButton, FormControl, InputLabel } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';

import { userActions } from '../../Redux/actions/userActions';
import picture from '../../Assets/login.png';
import { DefaultSnackbar } from '../../Components';
import './Login.css'

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [hide, setHide] = useState(true);
    const error = useSelector((state) => state.user);

    const dispatch = useDispatch();

    function handleSubmit(e) {
        e.preventDefault();

        if (email && password) {
            dispatch(userActions.login(email, password));
            if (error.error)
                enqueueSnackbar("Erreur de connexion : " + error.error)
        } else enqueueSnackbar("Veuillez remplir tous les champs");
    }

    return (
        <div className="loginContainer">
            <DefaultSnackbar />
            <div className="loginContainer-left">
                <form className="loginContent" onSubmit={handleSubmit}>
                    <div className="title">Connexion</div>
                    <TextField
                        label="Email"
                        variant="outlined"
                        type='email'
                        onChange={e => setEmail(e.target.value)}
                        fullWidth
                    />
                    <div className="password">
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="password-label">Mot de passe</InputLabel>
                            <OutlinedInput
                                label="Mot de passe"
                                type={hide ? 'password' : 'text'}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setHide(!hide)}
                                            edge="end"
                                        >
                                            {hide ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <div className="forget-password" onClick={() => window.location.href = `/forgot-password`}>Mot de passe oublié ?</div>
                    </div>
                    <button className="button" type="submit">Valider</button>
                    <div className="cta" onClick={() => window.location.href = `/register`}>
                        Notre solution vous intéresse ? <span>Inscrivez-vous !</span>
                    </div>
                </form>
                <footer className="copyright">© 2024 Sésamoïde</footer>
            </div>
            <div className="loginContainer-right">
                <img className="picture" src={picture} alt="Page de connexion" />
            </div>
        </div>
    );
}