import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Route, Link, Switch } from 'react-router-dom';

import { styled, useTheme, alpha } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { Box, Autocomplete, InputBase, Toolbar, List, CssBaseline, Typography, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import { Menu, ChevronLeft, ChevronRight, Search, PowerSettingsNew, Home, AssignmentInd, Calculate, TipsAndUpdates, Settings } from '@mui/icons-material';

import useClasses from './UseClasses';

import { userActions } from '../Redux/actions/userActions';

import HomePage from "../Pages/Home/Home"
import Patients from "../Pages/Patients/Patients"
import Patient from "../Pages/Patient/Patient"
import Consultation from "../Pages/Consultation/Consultation"
import Accounting from "../Pages/Accounting/Accounting"
import Advice from "../Pages/Advice/Advice"
import SettingsPage from "../Pages/Settings/Settings"

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    backgroundColor: '#EDEDED'
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    backgroundColor: '#EDEDED'
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        [theme.breakpoints.up('sm')]: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }
    }),
    backgroundColor: '#7F976C'
}));

const Drawer = styled(MuiDrawer)(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function CustomDrawer() {
    const theme = useTheme();
    const classes = useClasses(styles);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const date = new Date();
    const user = useSelector((state) => state.user);
    const patients = useSelector((state) => state.user.patients)

    useEffect(() => {
        dispatch(userActions.getPatients(user.userId));
    }, [dispatch, user.userId]);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleSearch = (item) => {
        window.location.href = `/patients/${item._id}`;
    };

    const disconnect = () => {
        dispatch(userActions.logout());
    };

    const drawer = (
        <div style={{ height: "calc(100% - 64px)" }}>
            <DrawerHeader>
                <IconButton onClick={handleDrawerToggle}>
                    {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
                </IconButton>
            </DrawerHeader>
            <List style={{ height: "100%" }} onClick={handleDrawerClose} >
                <ListItem divider key="Home" disablePadding component={Link} to='/' >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color: '#000'
                        }}><Home /></ListItemIcon>
                        <ListItemText primary="Accueil" sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItem divider key="Patients" disablePadding component={Link} to='/patients' >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color: '#000'
                        }}><AssignmentInd /></ListItemIcon>
                        <ListItemText primary="Patients" sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItem divider key="Accounting" disablePadding component={Link} to='/accounting' >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color: '#000'
                        }}><Calculate /></ListItemIcon>
                        <ListItemText primary="Comptabilité" sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItem divider key="Advice" disablePadding component={Link} to='/advice' >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color: '#000'
                        }}><TipsAndUpdates /></ListItemIcon>
                        <ListItemText primary="Conseils" sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="Settings" disablePadding component={Link} to='/settings'
                    style={{ position: "absolute", bottom: 0 }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color: '#000'
                        }}><Settings /></ListItemIcon>
                        <ListItemText primary="Paramètres" sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { [theme.breakpoints.up('sm')]: { display: 'none' } }),
                        }}
                    >
                        <Menu />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" className={classes.hideSm}>
                        Sésamoïde
                    </Typography>
                    <div className={classes.grow}></div>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <Search />
                        </div>
                        <Autocomplete
                            id='autoSearch'
                            name='autoSearch'
                            options={patients}
                            getOptionLabel={(option) => option.firstname + ' ' + option.name}
                            renderOption={(props, option) => <li key={option._id} {...props}>{option.firstname + ' ' + option.name}</li>}
                            autoComplete
                            selectOnFocus
                            clearOnBlur
                            disableClearable
                            loadingText="Recherche..."
                            noOptionsText="Aucun résultat"
                            onChange={(event, newValue) => handleSearch(newValue)}
                            renderInput={(params) => <InputBase
                                id='inputSearch'
                                name='inputSearch'
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                placeholder="Rechercher..."
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                            />}
                        />
                    </div>
                    <div className={classes.grow}></div>
                    <Typography variant="h6" noWrap className={classes.hideMd}>
                        {new Intl.DateTimeFormat("fr-FR", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit",
                        }).format(date)}
                    </Typography>
                    <IconButton
                        color="inherit"
                        onClick={disconnect}
                    >
                        <PowerSettingsNew />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <BrowserRouter>
                <Drawer
                    variant="temporary"
                    open={open}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" }
                    }}>
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    open={open}
                    sx={{
                        display: { xs: "none", sm: "block" }
                    }}>
                    {drawer}
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1 }}>
                    <DrawerHeader />
                    <Switch>
                        <Route exact path="/" > <HomePage /> </Route>
                        <Route exact path="/patients/:id" > <Patient /> </Route>
                        <Route exact path="/patients" > <Patients /> </Route>
                        <Route exact path="/consultation/:patientId/:id?" > <Consultation /> </Route>
                        <Route exact path="/accounting" > <Accounting /> </Route>
                        <Route exact path="/advice" > <Advice /> </Route>
                        <Route exact path="/settings" > <SettingsPage /> </Route>
                    </Switch>
                </Box>
            </BrowserRouter>
        </Box>
    );
}

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: theme.spacing(6),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '40ch',
        },
    },
    hideMd: {
        display: 'block',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    hideSm: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    }
})

export default withStyles(styles)(CustomDrawer);