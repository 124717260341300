import { styled } from '@mui/material/styles';
import { SnackbarProvider, closeSnackbar, MaterialDesignContent } from 'notistack';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-default': {
        backgroundColor: "#ededed",
        color: "black",
        border: "1px solid black",
    }
}));

export default function DefaultSnackbar() {
    return (
        <SnackbarProvider
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            action={(snackbarId) => (
                <button className='snackbar-button' onClick={() => closeSnackbar(snackbarId)}>
                    Fermer
                </button>
            )}
            Components={{
                default: StyledMaterialDesignContent,
            }}
        />);
}