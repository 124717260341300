import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
} from "@mui/material";
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  FirstPage,
  LastPage,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";

import useClasses from "../../Components/UseClasses";
import { invoiceService } from "../../Redux/services";
import { RootState, InvoiceState } from "../../Types";

import "./Accounting.css";

export default function Accounting() {
  const classes = useClasses(styles);
  const user = useSelector((state: RootState) => state.user);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);

  useEffect(() => {
    invoiceService.getByUserId(user.userId).then((resp) => {
      setItems(resp);
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div
        style={{
          flexShrink: 0,
          marginLeft: theme.spacing(2.5),
        }}
      >
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
        </IconButton>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <TableContainer style={{ overflow: "auto", height: "calc(100% - 50px)" }}>
        <Table stickyHeader size="medium">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader} align="left">
                Numéro
              </TableCell>
              <TableCell className={classes.tableHeader} align="left">
                Prénom
              </TableCell>
              <TableCell className={classes.tableHeader} align="left">
                Nom
              </TableCell>
              <TableCell className={classes.tableHeader} align="left">
                Date
              </TableCell>
              <TableCell className={classes.tableHeader} align="left">
                Prix
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow
                    key={row._id}
                    hover
                    component={Link}
                    to={`/patients/${row._id}`}
                  >
                    <TableCell align="left">{index}</TableCell>
                    <TableCell align="left">{row.patient_firstname}</TableCell>
                    <TableCell component="th" scope="row" align="left">
                      {row.patient_name}
                    </TableCell>
                    <TableCell align="left">
                      {row.last
                        ? new Intl.DateTimeFormat("fr-FR", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit",
                          }).format(new Date(row.date))
                        : "Aucune séance"}
                    </TableCell>
                    <TableCell align="left">{row.price}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ height: "50px" }}>
        <TablePagination
          count={items.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
          SelectProps={{
            native: true,
          }}
          ActionsComponent={TablePaginationActions}
          style={{ border: "none" }}
        />
      </div>
    </div>
  );
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "calc(100vh - 70px)",
    justifyItems: "center",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    height: "80%",
    width: "98%",
    [theme.breakpoints.up("sm")]: {
      width: 600,
      height: 530,
    },
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    borderRadius: "5px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  createButton: {
    backgroundColor: "#2980B9",
    color: "white",
  },
  titleModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentModal: {
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "space-between",
    height: "70%",
  },
  item: {
    width: "45%",
  },
  item1: {
    width: "100%",
  },
  tableHeader: {
    fontWeight: "bold",
    backgroundColor: "#EDEDED",
  },
});
