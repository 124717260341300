import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { TextField, Tabs, Tab, Box, IconButton } from "@mui/material";
import { Mode, Save } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import { PatientInfo, Anamnesis, Schema } from "../../Components";
import { patientActions } from "../../Redux/actions";
import { patientService, consultationService } from "../../Redux/services";
import { RootState, PatientState, ConsultationState } from "../../Types";

import "./Patient.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            paddingTop: 2,
            "@media screen and (max-width: 600px)": {
              paddingX: 1,
            },
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `panel-${index}-top`,
    "aria-controls": `tabpanel-${index}-top`,
  };
}

const HeaderTextField = styled(TextField)`
  && {
    & .MuiInputBase-root {
      font-size: 1.5em;
      font-weight: bold;
      max-width: 15ch;
    }
    & .MuiInput-input {
      text-overflow: ellipsis;
    }
  }
`;

export default function Patient() {
  const { id } = useParams<string>();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const [patient, setPatient] = useState<Partial<PatientState>>({});
  const [consultations, setConsultations] = useState<ConsultationState[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [newConsultation, setNewConsultation] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [consultation, setConsultation] = useState<ConsultationState>({
    date: new Date().toISOString().substring(0, 10),
    motif: "",
    free: false,
    price: 0,
    motifTotal: "",
    happenedR: false,
    wakeUpR: false,
    medicationsR: false,
    irradiationsR: false,
    examsR: false,
    happened: "",
    wakeUp: "",
    medications: "",
    irradiations: "",
    exams: "",
    eva: "/10",
    head: "",
    chest: "",
    abdomen: "",
    pelvis: "",
    sleep: "",
    psychologic: "",
    expectations: "",
    workedOn: {
      global: [],
      head: [],
      organs: [],
    },
    patientId: id,
    userId: user.userId,
  });

  useEffect(() => {
    patientService.getById(id).then((resp) => {
      setPatient(resp);
    });
    consultationService.getByPatientId(id).then((resp) => {
      setConsultations(resp);
    });
  }, [id]);

  const handleChange = (event) => {
    const target = event.target.id ? event.target.id : event.target.name;
    setPatient({ ...patient, [target]: event.target.value });
  };

  const handleChangeIndex = (event, newValue) => {
    setIndex(newValue);
  };

  const handleNewConsultation = (event) => {
    setNewConsultation(true);
    setEdit(true);
    setIndex(1);
  };

  const handleValidation = () => {
    let formIsValid = true;

    if (!patient.firstname) formIsValid = false;

    // if (typeof patient.firstname !== "undefined") {
    //     if (!patient.firstname.match(/^[a-zA-Z]+$/))
    //         formIsValid = false;
    // }

    if (!patient.name) formIsValid = false;

    // if (typeof patient.name !== "undefined") {
    //     if (!patient.name.match(/^[a-zA-Z]+$/))
    //         formIsValid = false;
    // }

    // if (!patientParams.mail) {
    //     formIsValid = false;
    //     errors["email"] = "Ne peut être vide.";
    // }

    // if (typeof fields["email"] !== "undefined") {
    //     let lastAtPos = fields["email"].lastIndexOf('@');
    //     let lastDotPos = fields["email"].lastIndexOf('.');

    //     if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
    //         formIsValid = false;
    //         errors["email"] = "Email non valide.";
    //     }
    // }

    return formIsValid;
  };

  const handleSubmit = () => {
    setEdit(!edit);
    if (handleValidation()) {
      dispatch(patientActions.update(patient));
    } else {
      alert("Formulaire incorrect.");
    }
  };

  return (
    <div className="patient-container">
      <div className="patient-infos">
        <div className="patient-infos-top">
          <div className="patient-header">
            <HeaderTextField
              id="firstname"
              required
              value={patient.firstname}
              variant="standard"
              onChange={handleChange}
              InputProps={{ disableUnderline: !edit, readOnly: !edit }}
              inputProps={{
                style: { textAlign: "end" },
              }}
            />
            <HeaderTextField
              id="name"
              required
              value={patient.name}
              variant="standard"
              onChange={handleChange}
              InputProps={{ disableUnderline: !edit, readOnly: !edit }}
            />
          </div>
          {!newConsultation ? (
            <IconButton
              onClick={() => (edit ? handleSubmit() : setEdit(!edit))}
            >
              {edit ? (
                <Save sx={{ color: "#000" }} />
              ) : (
                <Mode sx={{ color: "#000" }} />
              )}
            </IconButton>
          ) : null}
        </div>
        {!newConsultation ? (
          <button
            disabled={patient._id ? false : true}
            className="patient-button create"
            onClick={handleNewConsultation}
          >
            Nouvelle consultation
          </button>
        ) : null}
        {newConsultation ? (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={index}
                onChange={handleChangeIndex}
                variant="fullWidth"
              >
                <Tab label="Patient" {...a11yProps(0)} />
                <Tab label="Consultation" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={index} index={0}>
              <PatientInfo
                userId={user.userId}
                id={id}
                patient={patient}
                setPatient={setPatient}
                edit={edit}
                consultations={consultations}
              />
            </TabPanel>
            <TabPanel value={index} index={1}>
              <Anamnesis
                consultation={consultation}
                setConsultation={setConsultation}
                edit={edit}
                setEdit={setEdit}
                patientId={id}
                submitPatient={handleSubmit}
              />
            </TabPanel>
          </Box>
        ) : (
          <PatientInfo
            userId={user.userId}
            id={id}
            patient={patient}
            setPatient={setPatient}
            edit={edit}
            consultations={consultations}
          />
        )}
      </div>
      {index === 1 ? (
        <Schema
          consultation={consultation}
          setConsultation={setConsultation}
          edit={edit}
          patient={patient}
          isConsultation={true}
        />
      ) : (
        <Schema
          consultation={consultations.length > 0 ? consultations[0] : null}
          patient={patient}
          isConsultation={false}
        />
      )}
    </div>
  );
}
