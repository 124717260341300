import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { history } from './Helpers';
import { PrivateRoute } from './Components/PrivateRoute';
import { CustomDrawer } from './Components';

import Register from './Pages/Register/Register';
import Login from './Pages/Login/Login';
import Forgot from './Pages/Forgot-password/Forgot';
import Reset from './Pages/Reset-password/Reset';

const App = () => {

  const isSecureConnection = () => {
    return window.location.protocol === "https:";
  };

  const isLocalUrl = () => {
    const { hostname, protocol } = window.location;
    return protocol === "http:" && (hostname === "localhost" || /^127(?:\.(?:25[0-5]|2[0-4]\d|[0-1]?\d{1,2})){3}$/u.test(hostname));
  };

  const renderAppContent = () => {
    if (!isSecureConnection() && !isLocalUrl()) {
      return <Redirect to={`https://${window.location.host}${window.location.pathname}`} />;
    }

    return (
      <Router history={history}>
        <Switch>
          <PrivateRoute exact path="/" component={CustomDrawer} />
          <PrivateRoute exact path="/patients" component={CustomDrawer} />
          <PrivateRoute exact path="/patients/:id" component={CustomDrawer} />
          <PrivateRoute exact path="/consultation/:patientId/:id?" component={CustomDrawer} />
          <PrivateRoute exact path="/accounting" component={CustomDrawer} />
          <PrivateRoute exact path="/advice" component={CustomDrawer} />
          <PrivateRoute exact path="/settings" component={CustomDrawer} />
          <Route path="/register" component={Register} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={Forgot} />
          <Route path="/reset-password/:id/:token" component={Reset} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    );
  };

  return renderAppContent();
};

export default App;