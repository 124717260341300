import React, { useState, useEffect } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { Healing, Masks, Medication } from '@mui/icons-material';

import './HistoryTimeline.css'

function groupItems(array, property) {
    return array.reduce(function (groups, item) {
        var date = item[property];
        if (typeof (date) == 'object')
            date = date.format();
        var name = date.substring(0, 4)
        var group = groups[name] || (groups[name] = []);
        group.push(item);
        return groups;
    }, {});
}

export function HistoryTimeline(props) {
    const [data, setData] = useState(props.data);
    const [items, setItems] = useState([]);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setData(props.data);
    }, [props])

    useEffect(() => {
        if (checked) {
            let traumas = props.data.trauma;
            let surgeries = props.data.surgery;
            let medics = props.data.medic;
            if (traumas.length > 0) {
                traumas = traumas.filter(function (element) {
                    return element.important === true;
                })
            }
            if (surgeries.length > 0) {
                surgeries = surgeries.filter(function (element) {
                    return element.important === true;
                })
            }
            if (medics.length > 0) {
                medics = medics.filter(function (element) {
                    return element.important === true;
                })
            }
            setData({
                trauma: traumas,
                surgery: surgeries,
                medic: medics,
                treatment: props.data.treatment
            })
        }
        else {
            setData(props.data);
        }
    }, [props, checked])

    useEffect(() => {
        let elements = [];
        for (let i in items) {
            elements.push({ element: document.getElementById('base-' + i), length: items[i].length })
        }
        if (elements.length > 0) {
            elements.forEach((element) => {
                let ewidth = 100 + (element.length >= 2 ? element.length - 2 : 0) * 50;
                element.element.style.width = ewidth + "px";
            })
        }
    }, [items])

    useEffect(() => {
        let tmp = [];
        if (data.trauma.length > 0) {
            data.trauma.forEach(element => {
                if (!isNaN(Date.parse(element.date))) {
                    element.type = "trauma";
                    tmp.push(element)
                }
            });
        }
        if (data.surgery.length > 0) {
            data.surgery.forEach(element => {
                if (!isNaN(Date.parse(element.date))) {
                    element.type = "surgery";
                    tmp.push(element)
                }
            });
        }
        if (data.medic.length > 0) {
            data.medic.forEach(element => {
                if (!isNaN(Date.parse(element.date))) {
                    element.type = "medic";
                    tmp.push(element)
                }
            });
        }
        tmp.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
        var groups = groupItems(tmp, 'date');
        setItems(groups)
    }, [data, props])

    const handleMouseEnter = (e, id) => {
        e.preventDefault();
        const popup = document.getElementById(`popup-${id}`);
        popup.style.display = "flex";
    };

    const handleMouseLeave = (e, id) => {
        e.preventDefault();
        const popup = document.getElementById(`popup-${id}`);
        popup.style.display = "none";
    };

    const renderFilter = () => {
        return (
            Object.keys(items).length === 0 ? null :
                <FormControlLabel
                    value="historyTimeline-switch"
                    control={<Switch checked={checked} onChange={() => {
                        setChecked(!checked)
                    }} />}
                    label="Importants uniquement"
                    labelPlacement="start"
                />
        )
    }

    const renderYears = () => {
        let yearItems = [];
        for (let i in items) {
            let important = false;
            yearItems.push(
                <div key={i} className='historyTimeline-year-container'>
                    <div className='historyTimeline-year-items'>
                        {items[i].map(function (item, index) {
                            const id = i + '-' + index;
                            return <div id={id} key={id} className='historyTimeline-year-content' onMouseEnter={(e) => handleMouseEnter(e, id)} onMouseLeave={(e) => handleMouseLeave(e, id)}>
                                {item.important ? important = true : important = false}
                                <div id={'popup-' + id} className='historyTimeline-popup'>
                                    <div>{new Intl.DateTimeFormat("fr-FR", {
                                        year: "numeric",
                                        month: "2-digit",
                                    }).format(new Date(item.date))}</div>
                                    {item.label}
                                </div>
                                {item.type === "trauma" ? <Healing className='historyTimeline-year-icon' /> : item.type === "surgery" ? <Masks className='historyTimeline-year-icon' /> : <Medication className='historyTimeline-year-icon' />}
                                <div className='historyTimeline-year-line' />
                            </div>
                        })}
                    </div>
                    <div id={'base-' + i} className={important ? 'historyTimeline-year-base important' : 'historyTimeline-year-base'}>{i}</div>
                </div >
            )
        }
        return <div className='historyTimeline-container'>{yearItems}</div>;
    }

    return (
        <div className='historyTimeline'>
            {renderFilter()}
            {renderYears()}
        </div>
    );
}